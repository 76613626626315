import React, {Fragment} from 'react'
import LinkTiles from './header-link-tiles'
import HeaderTopBar from './header-top-bar'
import HeaderHamburgerMenu from './header-hamburger-menu'
import { NavLink } from 'react-router-dom'
import { metadata } from '../../../app-config'
import config from '../../../config.json'
import axios from 'axios'
import StoreManager from '../../../redux/store-manager'
import reducers from '../../../redux/reducers'

import { connect } from 'react-redux'
StoreManager.registerReducers(reducers)

require ('./header.scss')

const Header = props =>{

	const payload = {
		"width":window.innerWidth || document.body.clientWidth,
		"height":window.innerHeight || document.body.clientHeight
	}

	 axios.post('api/BI/',payload)
		.then(
			response => {
				// console.log(response.data)
			}
		)
	const {headerLinks} = props

	return (
		<Fragment>
			<HeaderTopBar
				leftSection={
					{
						icon:'assets/img/icons/mail.svg',
						icon_text:'',
						icon_width:'22px',
						linkValue:`mailto:${config.CONTACT_INFORMATION.EMAIL}`,
						value:config.CONTACT_INFORMATION.EMAIL
					}
				}
				rightSection={
					{
						icon:'assets/img/icons/phone.svg',
						icon_text:'',
						icon_width:'22px',
						linkValue:`mailto:${config.CONTACT_INFORMATION.EMAIL}`,
						value:`Phone: ${config.CONTACT_INFORMATION.PRIMARY_MOBILE}`
					}
				}

			/>
			<div className="header">
				<HeaderHamburgerMenu
					title={config.WEBSITE_NAME}
					links={headerLinks}
					image={config.HEADER_IMAGE}
				/>
				<div className="headerContent">
					<div className="logoContainer">
						<NavLink to={ {pathname: metadata.home.route}} title={metadata.home.title} activeClassName="active"  >
							<img className='logo' src={config.HEADER_IMAGE} title={metadata.home.title} alt={metadata.home.title} />
						</NavLink>
					</div>
					<div className="linksContainer">
						{headerLinks && (
							<LinkTiles links={headerLinks} linksDirection='horizontalContainer' />
						)}

					</div>
				</div>
			</div>
		</Fragment>

	)

}

const mapStateToProps = (state, ownProps)  =>{
	const headerLinks = state.navigation ? state.navigation.header.links : []
	return {
	 	headerLinks
	 }
}

export default  connect(mapStateToProps)(Header)
// export default Header
