import React, {useEffect, useState, Fragment} from 'react'
import { NavLink } from 'react-router-dom'

import {connect} from 'react-redux'
import {toggleHamburger} from "../header/redux/action";
import {gsap} from "gsap";

const HandleLinkRender = props =>{
  const myPath = window.location.pathname
  const [linkClicked, setLinkClicked] = useState(false)

  //hook to check if link clicked
  useEffect(() => {
    if(linkClicked === false)return
    props.toggleHamburger()
    setLinkClicked(false)
  }, [linkClicked])

  const {link, nakedLink} = props
  if(!link)return

  const linkClickedFunc = props =>{
    setLinkClicked(true)
  }

  const linkRender = () =>{
    return(
      <Fragment>
        {link.internalLink && (
          <NavLink
            to={ {pathname: link.linkDetails.url}}
            title={link.linkDetails.label}
            activeClassName="active"
            onClick={linkClickedFunc}
            className={`${link.linkDetails.spacialClass ? link.linkDetails.spacialClass : ''}`}
          >
            {link.linkDetails.label}
          </NavLink>
        )}
        {!link.internalLink && (
          <a
            href={link.linkDetails.url ? link.linkDetails.url : '#'}
            target={link.linkDetails.target ? '' : '_blank'}
            onClick={linkClickedFunc}
            className={`${link.linkDetails.spacialClass ? link.linkDetails.spacialClass : ''}`}
          >{link.linkDetails.label}</a>
        )}
      </Fragment>
    )
  }

  return(
    <Fragment>
      {nakedLink && (
        linkRender()
      )}
      {!nakedLink &&(
        <div className={`${myPath === link.linkDetails.url ? 'active' : ''}`}>
          {linkRender()}
        </div>
      )}
    </Fragment>
  )
}

const mapStateToProps = (state, ownProps) => {
  const {hamburgerStatus} = state.minimenu ? state.minimenu : false
  return {hamburgerStatus}
}

const mapDispatchToProps = {
  toggleHamburger
}

export default connect(mapStateToProps, mapDispatchToProps)(HandleLinkRender)
