import axios from 'axios'
import history from '../history'
import { metadata } from '../app-config'

const forbiddenStatusCodes = [ 401, 403, 405, 406 ]


export const configureInterceptors = () => {
	/**
     * @name constructor
     * @desc intercept the outgoing http request and append the token (for Canon STS)
     * @desc calling them injectors cause i keep forgetting there interceptors
     * @param {Object} request - the request properties
     */
	if (window.location.hostname === 'localhost' || window.location.hostname === 'localhost/Access-Denied-Security-React') {
		// add new environment
		// console.log(window.location.hostname)
	}


	axios.interceptors.request.use(
		(request) => {

			return request
		},
		(error) => {
			console.error(error)
			return Promise.reject(error)
		}
	)
	// ---------------------------------------------
	//   HTTP INTERCEPTOR FOR AXIOS - RESPONSE
	// ---------------------------------------------
	axios.interceptors.response.use(
		(response) => {
			return response
		},
		(error) => {
			console.error(error)
			if (error.response && forbiddenStatusCodes.includes(error.response.status))
				history.push(metadata.accesserror.route)
			else return Promise.reject(error)
		}
	)
}
