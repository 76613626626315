import React, { useEffect, useState } from 'react'
import {connect} from 'react-redux'
import {NavLink, useHistory} from 'react-router-dom'
import { signIn, signOut } from '../../../../auth/actions'
import {toggleHamburger} from "../redux/action";
import HandleLinkRender from '../../LinkRender'
import { gsap } from "gsap"
require ('./HeaderLinkTiles.scss')

const HeaderLinkTiles = props =>{

  //todo below is duplication LinkRender, need to find a better way

  const [linkClicked, setLinkClicked] = useState(false)

  //hook to check if link clicked
  useEffect(() => {
    if(linkClicked === false)return
    props.toggleHamburger()
    setLinkClicked(false)
  }, [linkClicked])

  const linkClickedFunc = props =>{
    setLinkClicked(true)
  }

  const myPath = window.location.pathname
  const {links, linksDirection} = props
  const [stateLinks, setLinks] = useState(links)

  let history = useHistory()

  useEffect(() => {
    if(!stateLinks) return
    linksDirection === 'verticalContainer' ?
      gsap.fromTo(".linkTile", {x:-20}, {stagger: .05, x:0,  duration: .6})
      : gsap.fromTo(".linkTile", {y:-20}, {stagger: .1, y:0,  duration: .6})
  }, [stateLinks])

  return (
    <div className='linksTilesContainer' >
      <div className={linksDirection}>
      {stateLinks.map((link,index)=>{
          return(
            <div className={`linkTile ${link.linkDetails.specialClass || ''}${myPath === link.linkDetails.url ? 'active' : ''}`}
                 key={link.linkDetails.url}
                 onClick={() => {
                   if(link.internalLink)
                   {history.push(link.linkDetails.url)
                     linkClickedFunc()
                   }
                     else{
                     if(link.linkDetails.newtab === true){
                       const win = window.open(link.linkDetails.url, '_blank')
                       if (win != null) {
                         win.focus()
                       }
                     }
                     else{
                       window.location.href = link.linkDetails.url
                     }
                   }

                 }}

            >
              <HandleLinkRender
                link={link}
                afterClick={()=>(console.log('bla'))}
              />

            </div>
          )
      })}
      </div>
    </div>
  )

}
const mapStateToProps = state =>{
  const {hamburgerStatus} = state.minimenu ? state.minimenu : false

  return {
    isSignedIn: state.auth.isSignedIn,
    hamburgerStatus
  };
}

const mapDispatchToProps = {
  toggleHamburger,
  signIn,
  signOut
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLinkTiles);
