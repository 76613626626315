import { Workbox } from 'workbox-window'
// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.

const isLocalhost = Boolean(
    // window.location.hostname === 'localhost' ||
    //   // [::1] is the IPv6 localhost address.
    //   window.location.hostname === '[::1]' ||
    //   // 127.0.0.1/8 is considered localhost for IPv4.
    //   window.location.hostname.match(
    //     /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    //   )
    false
)

export default function register() {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
        const swUrl = `${process.env.PUBLIC_URL}/mcb-sw.js`

        const wb = new Workbox(swUrl)

        wb.addEventListener('installed', event => {
            if (event.isUpdate) {
                if (
                    window.confirm(
                        `New content is available!. Click OK to refresh`
                    )
                ) {
                    setTimeout(() => window.location.reload())
                }
            }
        })

        wb.register()
    }
}

export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(registration => {
            registration.unregister()
        })
        navigator.serviceWorker
            .getRegistrations()
            .then(function(registrations) {
                for (let registration of registrations) {
                    registration.unregister()
                }
            })
    }
}
