import React from 'react'
import {Router, Route, Switch, Redirect} from 'react-router-dom'
import history from '../history'
import { metadata } from '../app-config'
import {
    Landing,
    Services,
    Contact,
    About,
    GlossaryPage,
    SearchPage,
    GalleryPage,
    TestimonialsPage,
    BlogsPage,
    BlogPage,
    FaqsPage,
    ServicePage,
    ArticlesPage,
    ArticlePage
} from '../loader'

const Routes = () =>{
    return(
        <div className="bodyContentContainer">
        <Router history={history}>
            <div>
                <Switch>
                    <Redirect from="/" exact to={metadata.home.route} />
                        <Route path={metadata.home.route} exact component={Landing} />
                        <Route path={metadata.gallery.route} exact component={GalleryPage} />
                        <Route path={metadata.services.route} exact component={Services} />
                        <Route path={metadata.contact.route} exact component={Contact} />
                        <Route path={metadata.about.route} exact component={About} />

                        <Route path={metadata.glossary.route} exact component={GlossaryPage} />
                        <Route path={metadata.search.route} exact component={SearchPage} />
                        <Route path={metadata.testimonials.route} exact component={TestimonialsPage} />
                        <Route path={metadata.blogs.route} exact component={BlogsPage} />
                        <Route path={metadata.blog.route} exact component={BlogPage} />
                        <Route path={metadata.faqs.route} exact component={FaqsPage} />
                        <Route path={metadata.service.route} exact component={ServicePage} />
                        <Route path={metadata.articles.route} exact component={ArticlesPage} />
                        <Route path={metadata.article.route} exact component={ArticlePage} />

                </Switch>
             </div>
        </Router>
    </div>
);
};
export default Routes;
