import React, { Component } from 'react'
import { gsap } from "gsap"
require ('./bubbleLoading.scss')

class SparkLoading extends Component {
    constructor(props) {
        super(props)
        this.state = {}
        this.tl = new gsap.timeline({ repeat: -1 })
        this.tl2 = new gsap.timeline({ repeat: -1 })
    }

    componentDidMount() {
      const selector1 = '.st1'

      const selector2 = '.stx'

        this.tl
            .set(selector1, { autoAlpha: .9,scale: 2,x: '+=5' })
            .to(selector1, 0.7, {
                scale:1.8,
                //x: '+=5',
                transformOrigin: '50% 50%'
            })
          //x: '-=5'
            .to(selector1, 0.5, { scale: 2,  transformOrigin: '50% 50%' })

        this.tl2
            .set(selector2, { autoAlpha:.9,scale: 2})
            // .to(selector2, 1, {
            //     scale: 2,
            //    // x: '-=5',
            //     transformOrigin: '50% 50%'
            // })
            // .to(selector2, 0.5, { scale: 2, transformOrigin: '50% 50%' })

        //split loading text
    }

    render() {
        return (
            <div style={{width:'100px', height:'130px'}}>

              <svg >
               <g >
                  <g>
                    <polygon className="st1" points="21.3,28.5 29.1,28.5 16.8,50.2 47,21.3 37.1,21.3 47,5.7 35.3,5.7 		"/>
                  </g>
                  <g className="stx">
                    <path className="st0" d="M19.9,43c-3.5-3.5-5.5-8.2-5.5-13.6c0-5.7,2.4-11,6.7-14.8c0.5,0.1,2.6,0.6,3.4,0c0,0-2.4-0.9-3.1-2
                      s0-4.2,0-4.2c-1.8,0.7-2,2.8-2.2,3.5c-5.1,4.4-8.4,10.7-8.4,17.4c0,6.4,2.9,12.3,7.3,16.5L19.9,43z"/>
                                    <path className="st0" d="M53.9,17.8c-0.4-0.6-0.6-0.8-0.6-0.8l-0.2-0.4c0,0,0.7-1.8-1.3-3.5l-5,5.5c0.8,1.1,3.6,0.7,3.6,0.7l0.4,0.4
                      l0,0c1.7,2.9,2.6,6.3,2.6,9.7c0,10.8-8.8,19.6-19.6,19.6c-4.1,0-7.9-1.3-11.1-3.5L20.5,48c3.6,2.8,8.7,4.5,13.5,4.5
                      c12.7,0,23.1-10.4,23.1-23.1C57.1,25.3,55.1,19.6,53.9,17.8z"/>
                                    <g>
                      <g>
                        <path className="st0" d="M25.2,6.6c-2.1,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S27.3,6.6,25.2,6.6z M23.5,10.6l-0.4-0.2
                          L23.8,9l0.4,0.2L23.5,10.6z M25.8,12.4l-1.5-0.2l0.1-0.4l1.5,0.2L25.8,12.4z M26,10.3l-0.2-0.4l1.3-0.7l0.2,0.4L26,10.3z"/>
                      </g>
                    </g>
                                    <path className="st0" d="M52,12l-0.3-0.2c-0.1-0.1-0.3-0.1-0.5,0l-1,1.1l-2.8-2.5L46.9,11l2.8,2.5l-1.1,1.2l-2.8-2.5l-0.3,0.3l2.8,2.5
                      l-1.2,1.4l-2.8-2.5l-0.5,0.6l2.8,2.5l-1.1,1.3c-0.1,0.1-0.1,0.3,0,0.5l0.3,0.2c0.1,0.1,0.3,0.1,0.5,0l5.8-6.4
                      C52.1,12.3,52.1,12.1,52,12z"/>
                                    <path className="st2" d="M25.2,7.1c1.8,0,3.3,1.5,3.3,3.3s-1.5,3.3-3.3,3.3c-1.8,0-3.3-1.5-3.3-3.3S23.3,7.1,25.2,7.1 M25.2,6
                      c-2.4,0-4.4,2-4.4,4.4s2,4.4,4.4,4.4s4.4-2,4.4-4.4S27.6,6,25.2,6L25.2,6z"/>
                  </g>
                </g>
                </svg>
            </div>
        )
    }
}

export default SparkLoading
