import React from 'react'
import { StyledLoader /*StyledSpinner*/ } from './styles'
// import { compose, lifecycle, defaultProps } from 'recompose'
import { compose, defaultProps } from 'recompose'
// import { withFooterData } from '../../../utils'
import SparkLoading from './sparkLoading'
import BubbleLoading from './bubbleLoading'

const enhance = compose(
    defaultProps({
        backgroundColor: 'transparent',
        color: '#4c4c4b',
        size: '30px',
        thickness: '3px',
        position: 'absolute',
        paddingAround: '0px',
        fixedFooter: true
    }),
    // withFooterData
)


const Loading = enhance(
    (props) => {
        return (
            <StyledLoader
            >
                <BubbleLoading />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}
                >
                    {props.message}
                    <div className="saving">
                        <span>.</span>
                        <span>.</span>
                        <span>.</span>
                        <span>.</span>
                    </div>
                </div>
            </StyledLoader>
        )
    }
)

export default Loading
