import React, { Fragment,useState, useEffect,useRef } from 'react'
import LinkTiles from '../../header-link-tiles/HeaderLinkTiles'
require ('./MenuContent.scss')


const MenuContent = props =>{

  const {links,image} = props

  return (
    <Fragment>
      <div className="contentContainer">
     {image && (
       <img src={image} className='headerImg' />
     )}
        <div>
          {links && (<LinkTiles links={links} linksDirection='verticalContainer' />)}
        </div>
      </div>
    </Fragment>
  )
}

export default MenuContent
