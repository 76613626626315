import React, { Fragment,useState,useRef,useEffect } from 'react'
import ReactModal from 'react-modal'
import MenuContent from './menu-content'
import { connect } from 'react-redux'
import hamburgerReducer from '../redux/reducers'
import {toggleHamburger} from '../redux/action'
import StoreManager from '../../../../redux/store-manager'
import Animate from 'animate.css-react'
import { gsap } from "gsap"
StoreManager.registerReducers({minimenu:hamburgerReducer})

require ('./HeaderHamburgerMenu.scss')

const HeaderHamburgerMenu = props =>{
  //props get passed from the main header component
  const {title,links,image, hamburgerStatus} = props
  const hamburgerIcon = useRef()

  useEffect(() => {

    const {toggleHamburger} = props
    hamburgerIcon.current.className = hamburgerStatus ? 'active' : ''
    toggleHamburger(hamburgerStatus)
    gsap.fromTo(".modalContent", {x:-20}, { x:0,  duration: 1}).play()
    hamburgerStatus ? document.body.style.overflow = "hidden" : document.body.style.overflow = "auto"
  }, [hamburgerStatus])

  useEffect(() => {
    ReactModal.setAppElement('body')
  }, [])

  const toggleBurger = () => {
      // console.log(hamburgerStatus)
      props.toggleHamburger(!hamburgerStatus)
  }

  return (
    <Fragment>
      <div id="menuButton" onClick={toggleBurger}>
        <a id="hamburger-icon" href="#" title={title} ref={hamburgerIcon}>
          <span className="line line-1"></span>
          <span className="line line-2"></span>
          <span className="line line-3"></span>
        </a>
      </div>
      <ReactModal
        isOpen={hamburgerStatus}
        // isOpen={true}
        overlayClassName='modalOverlay'
        className='modalContent'
      >
        <MenuContent
          links={links}
        />
      </ReactModal>
    </Fragment>
  )
}

const mapStateToProps = (state, ownProps) => {
  const {hamburgerStatus} = state.minimenu ? state.minimenu : false
  return {hamburgerStatus}
}

const mapDispatchToProps = {
    toggleHamburger
}

export default connect(mapStateToProps,mapDispatchToProps)(HeaderHamburgerMenu)
