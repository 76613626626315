import React, { Component } from 'react'
import { gsap } from "gsap"
require ('./bubbleLoading.scss')

class BubbleLoading extends Component {
    constructor(props) {
        super(props)
        this.state = {}
        this.tl = new gsap.timeline({ repeat: -1 })
        this.tl2 = new gsap.timeline({ repeat: -1 })
    }

    componentDidMount() {
      const selector1 = '.st1'

      const selector2 = '.st0, .st2'

        this.tl
            .set(selector1, { autoAlpha: 0.7 })
            .to(selector1, 0.5, {
                scale: 0.8,
                //x: '+=5',
                transformOrigin: '50% 50%'
            })
          //x: '-=5'
            .to(selector1, 0.5, { scale: 1,  transformOrigin: '50% 50%' })

        // this.tl2
        //     .set('.st1', { autoAlpha: 0.7 })
        //     .to('.st1', 0.5, {
        //         scale: 0.2,
        //         x: '-=5',
        //         transformOrigin: '50% 50%'
        //     })
        //     .to('.st1', 0.5, { scale: 1, x: '+=5', transformOrigin: '50% 50%' })

        //split loading text
    }

    render() {
        return (
            <div style={{height:'90px'}}>

              <svg >
                <g>
                  <path className="st0"
                        d="M76.3,11.6L76,23.8l-3.8,0l0-2.4l-3,0.1l0,2.3l-3.8,0l-0.3-12.2l4.2-0.2l-0.1,6.9l2.9,0l-0.1-6.9L76.3,11.6z"
                  />
                                  <path className="st0" d="M88.7,16.8c0,1.2-0.2,3.7-0.7,7.5L84,23.7l0.1-1.6l-2.3,0.1l0.1,1.5l-4.1,0.5c-0.4-3.8-0.6-6.3-0.6-7.6
                    c0-1.7,0.5-3.1,1.6-4.1s2.4-1.6,4.2-1.6c1.7,0,3.1,0.6,4.2,1.7C88.2,13.7,88.7,15.1,88.7,16.8z M84.3,17.4c0-0.5-0.1-0.9-0.3-1.3
                    c-0.3-0.4-0.6-0.6-1.1-0.6c-0.4,0-0.8,0.2-1,0.5c-0.2,0.4-0.4,0.8-0.4,1.2c0,1,0,2,0.1,2.8l2.6,0C84.3,19.2,84.3,18.3,84.3,17.4z"
                                  />
                                  <path className="st0" d="M102.3,14.7c0,2.1-1.1,3.7-3.2,4.7l2.4,3.4l-3.7,2l-3.2-4.5V24h-4v-8.4c-0.4,0.1-0.8,0.2-1.2,0.3l-0.5-4
                    c2.9-0.7,5.4-1,7.6-1c1.5,0,2.7,0.3,3.8,0.8C101.7,12.3,102.3,13.4,102.3,14.7z M97.9,15.8c0-0.3-0.3-0.6-0.8-0.7
                    c-0.4-0.1-0.8-0.2-1.3-0.2c-0.3,0-0.7,0-1.1,0.1v2.6C96.8,17.3,97.9,16.7,97.9,15.8z"/>
                                  <path className="st0" d="M116,14.7c0,2.1-1.1,3.7-3.2,4.7l2.4,3.4l-3.7,2l-3.2-4.5V24h-4v-8.4c-0.4,0.1-0.8,0.2-1.2,0.3l-0.5-4
                    c2.9-0.7,5.4-1,7.6-1c1.5,0,2.7,0.3,3.8,0.8C115.3,12.3,116,13.4,116,14.7z M111.5,15.8c0-0.3-0.3-0.6-0.8-0.7
                    c-0.4-0.1-0.8-0.2-1.3-0.2c-0.3,0-0.7,0-1.1,0.1v2.6C110.4,17.3,111.5,16.7,111.5,15.8z"/>
                                  <path className="st0" d="M120.9,11.5c-0.2,2.6-0.3,5-0.3,7.2c0,2.1,0,3.8,0.1,5l-3.8,0.1c-0.1-1.6-0.2-3.3-0.2-5.3s0.1-4.3,0.2-7.2
                    L120.9,11.5z"/>
                                  <path className="st0" d="M133.1,12.9l-2.3,2.9c-1.1-1-2.2-1.5-3.5-1.5c-0.8,0-1.1,0.2-1.1,0.5c0,0.2,0.6,0.6,1.7,1.1
                    c1.5,0.6,2.6,1.1,3.1,1.5c1.1,0.8,1.7,1.6,1.7,2.6c0,1.3-0.5,2.4-1.6,3.3c-1,0.8-2.2,1.2-3.5,1.2c-2,0-3.9-1.1-5.9-3.2l2.3-2.9
                    c0.5,0.6,1.1,1,1.8,1.5c0.8,0.5,1.5,0.7,2.2,0.7c0.6,0,0.9-0.2,0.9-0.5c0-0.3-0.6-0.7-1.9-1.1c-1.7-0.6-2.8-1.1-3.5-1.5
                    c-1.2-0.8-1.9-1.8-1.9-2.9c0-1.2,0.6-2.3,1.8-3.1c1.1-0.7,2.2-1.1,3.5-1.1c1.1,0,2.2,0.2,3.3,0.7C131.5,11.5,132.4,12.1,133.1,12.9
                    z"/>
                </g>
                                <g>
                  <path className="st0" d="M95.7,45.6c-2.3,0.2-5,0.3-8.1,0.3l-1.8,0c-0.2-2.2-0.2-4.1-0.2-5.8c0-1.7,0.1-3.9,0.2-6.4l9.6-0.1l0,3.7
                    l-5.8,0.2l0,0.6l5.9,0l0.1,3l-5.9,0.2l0,0.7l6-0.3L95.7,45.6z"/>
                                  <path className="st0" d="M106.4,45.6c-2.8,0.2-4.9,0.3-6.4,0.3c-0.8,0-1.8,0-3,0c-0.1-1.7-0.1-3.4-0.1-5c0-1.6,0.1-4.1,0.2-7.4l3.9,0
                    c-0.1,2.9-0.1,4.7-0.1,5.5v3l5.4,0L106.4,45.6z"/>
                                  <path className="st0" d="M117.6,45.6c-2.3,0.2-5,0.3-8.1,0.3l-1.8,0c-0.2-2.2-0.2-4.1-0.2-5.8c0-1.7,0.1-3.9,0.2-6.4l9.6-0.1l0,3.7
                    l-5.8,0.2l0,0.6l5.9,0l0.1,3l-5.9,0.2l0,0.7l6-0.3L117.6,45.6z"/>
                                  <path className="st0" d="M129.9,44c-1.4,1.6-3.2,2.4-5.4,2.4c-1.8,0-3.3-0.6-4.5-1.7c-1.2-1.1-1.8-2.6-1.8-4.4c0-2.1,0.8-3.8,2.3-5.3
                    c1.5-1.4,3.3-2.1,5.4-2.1c1.5,0,2.7,0.2,3.5,0.5l-0.8,4.2c-0.9-0.2-1.7-0.4-2.1-0.4c-1.2,0-2.1,0.3-2.9,0.8
                    c-0.9,0.6-1.3,1.5-1.3,2.6c0,0.6,0.2,1.1,0.6,1.5c0.4,0.4,0.9,0.6,1.5,0.6c1.4,0,2.7-0.8,3.8-2.3L129.9,44z"/>
                                  <path className="st0"
                                        d="M141.1,36.9l-3.1,0.1l-0.3,9.3l-3.8,0l-0.1-9.2l-3.5,0.1l0.1-3.6l10.6-0.1L141.1,36.9z"/>
                                  <path className="st0" d="M154.9,36.7c0,2.1-1.1,3.7-3.2,4.7l2.4,3.4l-3.7,2l-3.2-4.5V46h-4v-8.4c-0.4,0.1-0.8,0.2-1.2,0.3l-0.5-4
                    c2.9-0.7,5.4-1,7.6-1c1.5,0,2.7,0.3,3.8,0.8C154.2,34.4,154.9,35.4,154.9,36.7z M150.4,37.8c0-0.3-0.3-0.6-0.8-0.7
                    c-0.4-0.1-0.8-0.2-1.3-0.2c-0.3,0-0.7,0-1.1,0.1v2.6C149.4,39.3,150.4,38.7,150.4,37.8z"/>
                                  <path className="st0" d="M159.9,33.6c-0.2,2.6-0.3,5-0.3,7.2c0,2.1,0,3.8,0.1,5l-3.8,0.1c-0.1-1.6-0.2-3.3-0.2-5.3
                    c0-1.9,0.1-4.3,0.2-7.2L159.9,33.6z"/>
                                  <path className="st0" d="M172.4,44c-1.4,1.6-3.2,2.4-5.4,2.4c-1.8,0-3.3-0.6-4.5-1.7s-1.8-2.6-1.8-4.4c0-2.1,0.8-3.8,2.3-5.3
                    c1.5-1.4,3.3-2.1,5.4-2.1c1.5,0,2.7,0.2,3.5,0.5l-0.8,4.2c-0.9-0.2-1.7-0.4-2.1-0.4c-1.2,0-2.1,0.3-2.9,0.8
                    c-0.9,0.6-1.3,1.5-1.3,2.6c0,0.6,0.2,1.1,0.6,1.5c0.4,0.4,0.9,0.6,1.5,0.6c1.4,0,2.7-0.8,3.8-2.3L172.4,44z"/>
                                  <path className="st0" d="M184.9,44.9l-3.8,1.5l-0.8-1.8l-3.8,0.1l-0.7,1.7l-3.8-1.5l6.4-12.4L184.9,44.9z M179.5,42.5l-0.9-2.2
                    l-0.9,2.3L179.5,42.5z"/>
                                  <path className="st0" d="M194.8,45.6c-2.8,0.2-4.9,0.3-6.4,0.3c-0.8,0-1.8,0-3,0c-0.1-1.7-0.1-3.4-0.1-5c0-1.6,0.1-4.1,0.2-7.4l3.9,0
                    c-0.1,2.9-0.1,4.7-0.1,5.5v3l5.4,0L194.8,45.6z"/>
                </g>
                                <g>
                  <g>
                    <path className="st1" d="M147.9,13l-2.3,2.9c-1.1-1-2.2-1.5-3.5-1.5c-0.8,0-1.1,0.2-1.1,0.5c0,0.2,0.6,0.6,1.7,1.1
                      c1.5,0.6,2.6,1.1,3.1,1.5c1.1,0.8,1.7,1.6,1.7,2.6c0,1.3-0.5,2.4-1.6,3.3c-1,0.8-2.2,1.2-3.5,1.2c-2,0-3.9-1.1-5.9-3.2l2.3-2.9
                      c0.5,0.6,1.1,1,1.8,1.5c0.8,0.5,1.5,0.7,2.2,0.7c0.6,0,0.9-0.2,0.9-0.5c0-0.3-0.6-0.7-1.9-1.1c-1.7-0.6-2.8-1.1-3.5-1.5
                      c-1.2-0.8-1.9-1.8-1.9-2.9c0-1.2,0.6-2.3,1.8-3.1c1.1-0.7,2.2-1.1,3.5-1.1c1.1,0,2.2,0.2,3.3,0.7C146.3,11.5,147.2,12.1,147.9,13z
                      "/>
                    <path className="st1" d="M161.7,15.1c0,1.6-0.8,3-2.4,4.1c-1.4,1-3,1.5-4.7,1.5l-0.8-1.7v5.1l-3.9,0l-0.2-8.5c-0.5,0.1-1,0.3-1.4,0.4
                      l-0.5-4c1.9-0.9,4.5-1.3,7.9-1.3c1.6,0,2.9,0.3,4,1C161,12.5,161.7,13.7,161.7,15.1z M157.2,15.7c0-0.6-0.6-0.9-1.8-0.9
                      c-0.5,0-1,0-1.5,0.1v2.8c0.8-0.1,1.5-0.3,2-0.6C156.7,16.8,157.2,16.3,157.2,15.7z"/>
                    <path className="st1" d="M173.9,16.8c0,1.2-0.2,3.7-0.7,7.5l-4.1-0.6l0.1-1.6l-2.3,0.1l0.1,1.5l-4.1,0.5c-0.4-3.8-0.6-6.3-0.6-7.6
                      c0-1.7,0.5-3.1,1.6-4.1s2.4-1.6,4.2-1.6c1.7,0,3.1,0.6,4.2,1.7S173.9,15.2,173.9,16.8z M169.5,17.4c0-0.5-0.1-0.9-0.3-1.3
                      c-0.3-0.4-0.6-0.6-1.1-0.6c-0.4,0-0.8,0.2-1,0.5c-0.2,0.4-0.4,0.8-0.4,1.2c0,1,0,2,0.1,2.8l2.6,0
                      C169.4,19.2,169.5,18.3,169.5,17.4z"/>
                    <path className="st1" d="M187.5,14.7c0,2.1-1.1,3.7-3.2,4.7l2.4,3.4l-3.7,2l-3.2-4.5V24h-4v-8.4c-0.4,0.1-0.8,0.2-1.2,0.3l-0.5-4
                      c2.9-0.7,5.4-1,7.6-1c1.5,0,2.7,0.3,3.8,0.8C186.8,12.4,187.5,13.4,187.5,14.7z M183,15.8c0-0.3-0.3-0.6-0.8-0.7
                      c-0.4-0.1-0.8-0.2-1.3-0.2c-0.3,0-0.7,0-1.1,0.1v2.6C181.9,17.3,183,16.7,183,15.8z"/>
                    <path className="st1"
                          d="M199,21.9l-2.3,2.2l-4.3-4.1l0,3.7l-3.8,0l-0.3-12.2l4.2-0.2l0,3.9l3.9-3.9l2.5,2l-3.6,4.3L199,21.9z"/>
                    <path className="st1" d="M210.7,13l-2.3,2.9c-1.1-1-2.2-1.5-3.5-1.5c-0.8,0-1.1,0.2-1.1,0.5c0,0.2,0.6,0.6,1.7,1.1
                      c1.5,0.6,2.6,1.1,3.1,1.5c1.1,0.8,1.7,1.6,1.7,2.6c0,1.3-0.5,2.4-1.6,3.3c-1,0.8-2.2,1.2-3.5,1.2c-2,0-3.9-1.1-5.9-3.2l2.3-2.9
                      c0.5,0.6,1.1,1,1.8,1.5c0.8,0.5,1.5,0.7,2.2,0.7c0.6,0,0.9-0.2,0.9-0.5c0-0.3-0.6-0.7-1.9-1.1c-1.7-0.6-2.8-1.1-3.5-1.5
                      c-1.2-0.8-1.9-1.8-1.9-2.9c0-1.2,0.6-2.3,1.8-3.1c1.1-0.7,2.2-1.1,3.5-1.1c1.1,0,2.2,0.2,3.3,0.7C209.1,11.5,210.1,12.1,210.7,13z
                      "/>
                  </g>
                                  <g>
                    <g>
                      <path className="st1" d="M147.9,13l-2.3,2.9c-1.1-1-2.2-1.5-3.5-1.5c-0.8,0-1.1,0.2-1.1,0.5c0,0.2,0.6,0.6,1.7,1.1
                        c1.5,0.6,2.6,1.1,3.1,1.5c1.1,0.8,1.7,1.6,1.7,2.6c0,1.3-0.5,2.4-1.6,3.3c-1,0.8-2.2,1.2-3.5,1.2c-2,0-3.9-1.1-5.9-3.2l2.3-2.9
                        c0.5,0.6,1.1,1,1.8,1.5c0.8,0.5,1.5,0.7,2.2,0.7c0.6,0,0.9-0.2,0.9-0.5c0-0.3-0.6-0.7-1.9-1.1c-1.7-0.6-2.8-1.1-3.5-1.5
                        c-1.2-0.8-1.9-1.8-1.9-2.9c0-1.2,0.6-2.3,1.8-3.1c1.1-0.7,2.2-1.1,3.5-1.1c1.1,0,2.2,0.2,3.3,0.7C146.3,11.5,147.2,12.1,147.9,13
                        z"/>
                      <path className="st1" d="M161.7,15.1c0,1.6-0.8,3-2.4,4.1c-1.4,1-3,1.5-4.7,1.5l-0.8-1.7v5.1l-3.9,0l-0.2-8.5c-0.5,0.1-1,0.3-1.4,0.4
                        l-0.5-4c1.9-0.9,4.5-1.3,7.9-1.3c1.6,0,2.9,0.3,4,1C161,12.5,161.7,13.7,161.7,15.1z M157.2,15.7c0-0.6-0.6-0.9-1.8-0.9
                        c-0.5,0-1,0-1.5,0.1v2.8c0.8-0.1,1.5-0.3,2-0.6C156.7,16.8,157.2,16.3,157.2,15.7z"/>
                      <path className="st1" d="M173.9,16.8c0,1.2-0.2,3.7-0.7,7.5l-4.1-0.6l0.1-1.6l-2.3,0.1l0.1,1.5l-4.1,0.5c-0.4-3.8-0.6-6.3-0.6-7.6
                        c0-1.7,0.5-3.1,1.6-4.1s2.4-1.6,4.2-1.6c1.7,0,3.1,0.6,4.2,1.7S173.9,15.2,173.9,16.8z M169.5,17.4c0-0.5-0.1-0.9-0.3-1.3
                        c-0.3-0.4-0.6-0.6-1.1-0.6c-0.4,0-0.8,0.2-1,0.5c-0.2,0.4-0.4,0.8-0.4,1.2c0,1,0,2,0.1,2.8l2.6,0
                        C169.4,19.2,169.5,18.3,169.5,17.4z"/>
                      <path className="st1" d="M187.5,14.7c0,2.1-1.1,3.7-3.2,4.7l2.4,3.4l-3.7,2l-3.2-4.5V24h-4v-8.4c-0.4,0.1-0.8,0.2-1.2,0.3l-0.5-4
                        c2.9-0.7,5.4-1,7.6-1c1.5,0,2.7,0.3,3.8,0.8C186.8,12.4,187.5,13.4,187.5,14.7z M183,15.8c0-0.3-0.3-0.6-0.8-0.7
                        c-0.4-0.1-0.8-0.2-1.3-0.2c-0.3,0-0.7,0-1.1,0.1v2.6C181.9,17.3,183,16.7,183,15.8z"/>
                      <path className="st1"
                            d="M199,21.9l-2.3,2.2l-4.3-4.1l0,3.7l-3.8,0l-0.3-12.2l4.2-0.2l0,3.9l3.9-3.9l2.5,2l-3.6,4.3L199,21.9z"/>
                      <path className="st1" d="M210.7,13l-2.3,2.9c-1.1-1-2.2-1.5-3.5-1.5c-0.8,0-1.1,0.2-1.1,0.5c0,0.2,0.6,0.6,1.7,1.1
                        c1.5,0.6,2.6,1.1,3.1,1.5c1.1,0.8,1.7,1.6,1.7,2.6c0,1.3-0.5,2.4-1.6,3.3c-1,0.8-2.2,1.2-3.5,1.2c-2,0-3.9-1.1-5.9-3.2l2.3-2.9
                        c0.5,0.6,1.1,1,1.8,1.5c0.8,0.5,1.5,0.7,2.2,0.7c0.6,0,0.9-0.2,0.9-0.5c0-0.3-0.6-0.7-1.9-1.1c-1.7-0.6-2.8-1.1-3.5-1.5
                        c-1.2-0.8-1.9-1.8-1.9-2.9c0-1.2,0.6-2.3,1.8-3.1c1.1-0.7,2.2-1.1,3.5-1.1c1.1,0,2.2,0.2,3.3,0.7C209.1,11.5,210.1,12.1,210.7,13
                        z"/>
                    </g>
                  </g>
                </g>
                                <g>
                  <g>
                    <polygon className="st1" points="21.3,28.5 29.1,28.5 16.8,50.2 47,21.3 37.1,21.3 47,5.7 35.3,5.7 		"/>
                  </g>
                                  <g>
                    <path className="st0" d="M19.9,43c-3.5-3.5-5.5-8.2-5.5-13.6c0-5.7,2.4-11,6.7-14.8c0.5,0.1,2.6,0.6,3.4,0c0,0-2.4-0.9-3.1-2
                      s0-4.2,0-4.2c-1.8,0.7-2,2.8-2.2,3.5c-5.1,4.4-8.4,10.7-8.4,17.4c0,6.4,2.9,12.3,7.3,16.5L19.9,43z"/>
                                    <path className="st0" d="M53.9,17.8c-0.4-0.6-0.6-0.8-0.6-0.8l-0.2-0.4c0,0,0.7-1.8-1.3-3.5l-5,5.5c0.8,1.1,3.6,0.7,3.6,0.7l0.4,0.4
                      l0,0c1.7,2.9,2.6,6.3,2.6,9.7c0,10.8-8.8,19.6-19.6,19.6c-4.1,0-7.9-1.3-11.1-3.5L20.5,48c3.6,2.8,8.7,4.5,13.5,4.5
                      c12.7,0,23.1-10.4,23.1-23.1C57.1,25.3,55.1,19.6,53.9,17.8z"/>
                                    <g>
                      <g>
                        <path className="st0" d="M25.2,6.6c-2.1,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S27.3,6.6,25.2,6.6z M23.5,10.6l-0.4-0.2
                          L23.8,9l0.4,0.2L23.5,10.6z M25.8,12.4l-1.5-0.2l0.1-0.4l1.5,0.2L25.8,12.4z M26,10.3l-0.2-0.4l1.3-0.7l0.2,0.4L26,10.3z"/>
                      </g>
                    </g>
                                    <path className="st0" d="M52,12l-0.3-0.2c-0.1-0.1-0.3-0.1-0.5,0l-1,1.1l-2.8-2.5L46.9,11l2.8,2.5l-1.1,1.2l-2.8-2.5l-0.3,0.3l2.8,2.5
                      l-1.2,1.4l-2.8-2.5l-0.5,0.6l2.8,2.5l-1.1,1.3c-0.1,0.1-0.1,0.3,0,0.5l0.3,0.2c0.1,0.1,0.3,0.1,0.5,0l5.8-6.4
                      C52.1,12.3,52.1,12.1,52,12z"/>
                                    <path className="st2" d="M25.2,7.1c1.8,0,3.3,1.5,3.3,3.3s-1.5,3.3-3.3,3.3c-1.8,0-3.3-1.5-3.3-3.3S23.3,7.1,25.2,7.1 M25.2,6
                      c-2.4,0-4.4,2-4.4,4.4s2,4.4,4.4,4.4s4.4-2,4.4-4.4S27.6,6,25.2,6L25.2,6z"/>
                  </g>
                </g>
                </svg>

            </div>
        )
    }
}

export default BubbleLoading
