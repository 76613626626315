import React from 'react'

require ('./headerTopBar.scss')

const HeaderTopBar = props =>{

  const {leftSection, rightSection} = props

  return(
    <div className="topRow">
      {props.leftSection && (
        <div id="leftContact">
          <div className="infoIcon" style={{width:leftSection.icon_width}}>
            <img src={leftSection.icon} alt={rightSection.icon_text}/>
          </div>
          <a href={leftSection.linkValue}>
            <div className="infoText">{leftSection.value}</div>
          </a>
        </div>
      )}

      {rightSection && (
        <div id="rightContact">
          <div className="infoIcon" style={{width:rightSection.icon_width}}>
            <img src={rightSection.icon} alt={rightSection.icon_text}/>
          </div>
          <div className="infoText">
            <a href={rightSection.linkValue}>
            {rightSection.value}
            </a>
          </div>
        </div>
      )}
    </div>
    )
}

export default HeaderTopBar;
