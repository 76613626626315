import React from 'react'
import Loading from './components/common/loading'
import Loadable from 'react-loadable'
import Animate from 'animate.css-react'

const AnimatedWrapper = props =>{
    const {loaded, componentProps} = props
    let Component = loaded

    return(
        <Animate
            enter="fadeIn" // on Enter animation
            leave="fadeOut" // on Leave animation
            appear="fadeIn" // on element Appear animation (onMount)
        >
            <Component {...componentProps}/>
        </Animate>);
}

const Landing = Loadable({
    loader: () => import('./pages/home'),
    render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
    loading: () => (
            <div className="d-flex section-layout-1200">
                <Loading
                    message="Loading...."
                />
            </div>
        )
})

const Services = Loadable({
    loader: () => import('./pages/services'),
    render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
    loading: () => (
        <div className="d-flex section-layout-1200">
            <Loading
                message="Loading Device Data...."
            />
        </div>
    )
})

const GalleryPage = Loadable({
    loader: () => import('./pages/gallery'),
    render(loaded, props) {
        let Component = loaded.default;
        return(
            <Animate
                enter="fadeIn" // on Enter animation
                leave="fadeOut" // on Leave animation
                appear="fadeIn" // on element Appear animation (onMount)
            >
                <Component {...props}/>
            </Animate>);
    },
    loading: () => (
        <div className="d-flex section-layout-1200">
            <Loading
                message="Loading Gallery ...."
            />
        </div>
    )
})


const Contact = Loadable({
    loader: () => import('./pages/contact'),
    render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
    loading: () => (
        <div className="d-flex section-layout-1200">
            <Loading
                message="Loading Contact Us......"
            />
        </div>
    )
})

const About = Loadable({
  loader: () => import('./pages/about'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading About Us ......"
      />
    </div>
  )
})



const ArticlesPage = Loadable({
  loader: () => import('./pages/articles'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading Articles Us ......"
      />
    </div>
  )
})
const ArticlePage = Loadable({
  loader: () => import('./pages/articles/article'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading Article ......"
      />
    </div>
  )
})
const ServicePage = Loadable({
  loader: () => import('./pages/services/service'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading Page ......"
      />
    </div>
  )
})
const FaqsPage = Loadable({
  loader: () => import('./pages/faqs'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading FAQs ......"
      />
    </div>
  )
})
const BlogsPage = Loadable({
  loader: () => import('./pages/blogs'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading Blogs ......"
      />
    </div>
  )
})
const BlogPage = Loadable({
  loader: () => import('./pages/blogs/blog'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading About Us ......"
      />
    </div>
  )
})
const TestimonialsPage = Loadable({
  loader: () => import('./pages/testimonials'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading Testimonials  ......"
      />
    </div>
  )
})

const SearchPage = Loadable({
  loader: () => import('./pages/search'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading Search Page ......"
      />
    </div>
  )
})
const GlossaryPage = Loadable({
  loader: () => import('./pages/glossary'),
  render(loaded, props) { return(<AnimatedWrapper loaded={loaded.default} componentProps={props} />)},
  loading: () => (
    <div className="d-flex section-layout-1200">
      <Loading
        message="Loading Glossary ......"
      />
    </div>
  )
})

export {
  Landing,
  Services,
  Contact,
  About,
  GlossaryPage,
  SearchPage,
  GalleryPage,
  TestimonialsPage,
  BlogsPage,
  BlogPage,
  FaqsPage,
  ServicePage,
  ArticlesPage,
  ArticlePage
}
